import {
  InternalStepProgress,
  PageWithIntro,
  StrainsConfirmation,
  StrainsForm,
  WizardLayout,
} from 'components';
import React, { useState } from 'react';
import {
  allState as allSkipState,
  createSkippableSectionBuilderForKey,
  strainsSkipKeyBuilder,
} from '../../../utilities/skippedEntries';
import {
  allState as allStrainState,
  createSaveStrainHandler,
} from '../../../utilities/strains';
import { deleteFormElement, getFormState } from '../../../utilities/formstate';

import { WistiaVideo } from 'components/widgets/WistiaVideo';
import YesNoModal from 'components/widgets/YesNoModal';
import { createQueriesHook as createAllQueries } from '../../../utilities/allQueries';
import { displayMessage } from 'src/toasts';
import { stepIsComplete } from '../../../utilities/steps';
import { strainsStep } from '../../../utilities/stepDefinitions';
import { useTracking } from '../../../utilities/hooks';
import { validId } from '../../../utilities/matchers';

const StrainsPage = () => {
  const { strainState } = allStrainState();
  const introInformation = getFormState('introInformation');
  const [strainIdToDelete, setStrainIdToDelete] = useState(null);
  const stepCompleted = stepIsComplete(strainsStep);
  const allQueries = createAllQueries();
  const { skipState } = allSkipState();
  const { skippingStrains, strains, canDeleteStrain } = allQueries;

  const skippableSectionBuilder = createSkippableSectionBuilderForKey({
    label: 'I have no strains',
    keyBuilder: strainsSkipKeyBuilder,
    skipState,
    canSkip: allQueries.canSkipStrains,
  });

  useTracking('Strains');

  const createDeleteStrainHandler = id => () => {
    setStrainIdToDelete(id);
  };

  const deleteStrain = () => {
    if (!canDeleteStrain(strainIdToDelete)) {
      setStrainIdToDelete(null);
      displayMessage(
        "Strain can't be deleted because it is in use by a plant or plant group"
      );
      return;
    }
    deleteFormElement(strainState, strainIdToDelete);
    setStrainIdToDelete(null);
  };

  const hideDeletionModal = () => {
    setStrainIdToDelete(null);
  };

  const strainSaveHandler = createSaveStrainHandler({
    strainState,
    completion: () => {},
  });

  const deleteModal = () => (
    <YesNoModal
      isOpen={validId(strainIdToDelete)}
      title="Delete Strain?"
      onYes={deleteStrain}
      onNo={hideDeletionModal}
    />
  );

  return (
    <PageWithIntro step={strainsStep}>
      <WizardLayout text>
        <h1>Add Strain Details</h1>
        <WistiaVideo id={strainsStep.intro.videoID} />

        {deleteModal()}
        <InternalStepProgress
          stepsConfig={[
            {
              step: 1,
              label: '1. Add Strain Details',
              component: (
                <StrainsForm
                  working={false}
                  initialValues={{}}
                  addedStrains={strains}
                  introInformation={introInformation.values}
                  createDeleteStrainHandler={createDeleteStrainHandler}
                  onSubmit={strainSaveHandler}
                  skippableSectionBuilder={skippableSectionBuilder}
                  skippingStrains={skippingStrains}
                />
              ),
            },
            {
              step: 2,
              label: '2. Confirm Strain Details',
              component: (
                <StrainsConfirmation
                  skippableSectionBuilder={skippableSectionBuilder}
                  stepCompleted={stepCompleted}
                  addedStrains={strains}
                  introInformation={introInformation.values}
                  working={strainState.working || false}
                  onSubmit={strainSaveHandler}
                  skippingStrains={skippingStrains}
                  createDeleteStrainHandler={createDeleteStrainHandler}
                />
              ),
            },
          ]}
        />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default StrainsPage;
